<div class="w-full flex flex-row justify-between items-center content-center gap-2">
    <button mat-icon-button *ngIf="isMobile()" [matMenuTriggerFor]="mainMenu">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu
        #mainMenu="matMenu"
        class="flex flex-col"
        xPosition="before">
        @for (item of menu(); track item) {
            <button mat-menu-item
                    (click)="navigateTo(item.page); triggerMenu()"
                    [class.active]="item.isHover || rla.isActive"
                    [routerLink]="item.url"
                    (mouseenter)="item.isHover = true"
                    (mouseleave)="item.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive">
                @if (item.icon) {
                    <mat-icon
                            [color]="rla.isActive || item.isHover ? '500' : undefined">{{item.icon}}</mat-icon>
                }
                <div [class.text-start]="isMobile()" class="two-lines-ellipsis word-break-word">
                    {{ item.tKey | translate }}
                </div>
            </button>
        }
    </mat-menu>
    <img src="../../../../assets/logos/logo-lmh-rr.jpg" class="header-logo">
    <div *ngIf="!isMobile()" class="h-full flex flex-row justify-center content-center items-center gap-4">
        @for (item of menu(); track item) {
            <button mat-button
                    (click)="navigateTo(item.page); triggerMenu()"
                    [class.active]="item.isHover || rla.isActive"
                    [routerLink]="item.url"
                    (mouseenter)="item.isHover = true"
                    (mouseleave)="item.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive">
                @if (item.icon) {
                    <mat-icon
                            [color]="rla.isActive || item.isHover ? '500' : undefined">{{item.icon}}</mat-icon>
                }
                <div [class.text-start]="isMobile()" class="two-lines-ellipsis word-break-word">
                    {{ item.tKey | translate }}
                </div>
            </button>
        }
    </div>

</div>