import { Directive, ElementRef, Renderer2, inject, effect } from '@angular/core';
import { AppService } from '@shared/services/app.service';

@Directive({
	standalone: true,
	selector: '[isMobile]',
})
export class IsMobileDirective {
	private el: ElementRef = inject(ElementRef);
	private renderer: Renderer2 = inject(Renderer2);

	isMobile = inject(AppService).isMobile;

	constructor() {
		effect(() => {
			if (this.isMobile()) this.renderer.addClass(this.el.nativeElement, 'is-mobile');
			else this.renderer.removeClass(this.el.nativeElement, 'is-mobile');
		});
	}
}
